<template>
  <div>
    <v-app-bar short dense color="rgb(33, 37, 41)" app>
      <div class="app-bar-content">
        <div class="avatar"><v-img @click="drawer = !drawer" src="/img/logo.jpg"  width="55" height="55" ></div>
        <h2 class="transition-default">Caseta los Alcoreños 2022</h2>
        <v-btn ripple color="rgb(0,255,255)" @click="drawer = !drawer">
          <v-icon title="Menu">mdi-menu</v-icon>
          <div v-if = drawer>Ocultar</div>
          <div v-else>Mostrar</div>
        </v-btn>
      </div>
    </v-app-bar>
    <v-navigation-drawer
        v-model="drawer"
        absolute
        left
        temporary
        mini-variant-width="100"
         app
    >
      <div id="sidebar">
        <div class="sidebar-toggle">
          <div @click="changeToggleState()" id="btn-toggle">
            <v-icon id="btn-toggle-icon" x-large>mdi-chevron-left</v-icon>
          </div>
        </div>
        <div class="sidebar-body" @click="changeToggleState()">
     
          <div class="sidebar-links">
            <small>Carta</small>
            <hr class="divider" />
            <div class="links">
              <a
                v-for="link in menuLinks"
                :key="link.title"
                :OnClick="link.path"
             
              >
                <v-icon :title="link.title" class="icon"
                  >mdi-{{ link.icon }}</v-icon
                >
                <v-slide-x-transition mode="in-out" leave-absolute>
                  <div class="link-title" v-show="menuCompact.hidden">
                    {{ link.title }}
                  </div>
                </v-slide-x-transition>
              </a>
            </div>
          </div>
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import "./style.css";

//import Swal from "sweetalert2";



export default {
  name: "Sidebar",
  data: () => {
    return {
      drawer: true,
      menuCompact: {
        hidden: true,
      },

      profileInfo: {
        name: "Caseta Los Alcoreños",
        photo: {
          file: "logo.jpg",
          title: "Carta Los Alcoreños",
        },
      },
      memberActive: true,
      menuLinks: [
        { path: "cambia_endpoint('BEBIDA')", title: "BEBIDAS", icon: "home"},
        { path: "cambia_endpoint('VARIOS')", title: "VARIOS", icon: "home" },
        { path: "cambia_endpoint('CHACINA_Y_PANES')", title: "CHACINA Y PANES", icon: "home" },
        { path: "cambia_endpoint('MARISCOS')", title: "MARISCOS Y ALIÑOS", icon: "home" },
        { path: "cambia_endpoint('REVUELTOS_Y_TORTILLAS')", title: "REVUELTOS Y TORTILLAS", icon: "home" },
        { path: "cambia_endpoint('PESCADOS')", title: "PESCADO", icon: "home" },
        { path: "cambia_endpoint('CARNE')", title: "CARNE", icon: "home" },
        { path: "cambia_endpoint('FOTO')", title: "FOTO CARTA", icon: "image" },
        { path: "cambia_endpoint('MUSICA')", title: "MUSICA", icon: "image" },
        { path: "cambia_endpoint('CUENTA')", title: "CUENTA", icon: "file" },
        //{ path: "cambia_endpoint('SALIR')", title: "SALIR", icon: "exit" },
        ],
    };
  },
  mounted() {
       
  },

  methods: {
    changeToggleState() {
      this.drawer=false;
      return;
      //let btnToggleIcon = document.getElementById("btn-toggle-icon");
      //this.menuCompact.hidden = !this.menuCompact.hidden;
      //if (this.menuCompact.hidden) {
      //  return (btnToggleIcon.style.transform = "rotateY(0deg)");
      //} else {
      //  return (btnToggleIcon.style.transform = "rotateY(180deg)");
     // }
    },

  


},

 
};

</script>
