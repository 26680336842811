import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import VueCookies from 'vue-cookies';
//import Constantes from "./Constantes.js";
Vue.use(VueCookies);


Vue.config.productionTip = false;

new Vue({
  vuetify,
  axios,
  render: function (h) {
    return h(App);
  },
  
}).$mount("#app");


