<template>
  <v-app>
    <Sidebar :style="{visibility: showmenu ? 'visible' : 'hidden' }"/>
    <v-main>
      <v-container fluid class="main-container">
       <div class="container">
          <iframe class="responsive-iframe" id="endpoint"
            title="Inline Frame Example"
            width="100%"
            height="100%"
            frameborder="0"
            src="">
          </iframe>
          <div class="loader-wrapper" :style="{visibility: showLoader ? 'visible' : 'hidden' }">
            <v-card  ripple color="rgb(0,255,255)">
              <v-card-title>{{ page.title }}</v-card-title>
              <v-card-subtitle>{{ page.subtitle }}</v-card-subtitle>
            </v-card>
         </div>
      </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Sidebar from "./components/sidebar/index.vue";

//import Swal from "sweetalert2";


export default {
  name: "App",
  components: {
    Sidebar,
  },

  mounted() {

this.hideLoader();
    this.checkToken();

  },

  data: () => ({
   showLoader: true,
   showmenu: false,
    page: {
      title: "Bienvenido a los Alcoreños",
      subtitle:
        "Mensaje de bienvenida",
    },
  }),

   methods: {
    hideLoader() {
      // using setTimeout to programmatically hide loader
      setTimeout(() => {this.showLoader = false }, 2000)
      },
   
      async checkToken() {

         this.showmenu =true;
         const iFrame = document.getElementById('endpoint');
         iFrame.src = "./zaafaa.html";
         //let a=this.getCookie("token")
         return;
      
    },
    
    getCookie(cookieName) {

      //let cookie = {};
      const username = this.$cookies.get(cookieName);
      return username;

      /*document.cookie.split(";").forEach(function (el) {
        let [key, value] = el.split("=");
        cookie[key.trim()] = value;
      });
      return cookie[cookieName];*/
    },

  
   },



};


</script>

<style>
.container {
  position: relative;
  overflow: hidden;
  width: 100%;
   height: 100%;
  padding-top: 100%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

</style>